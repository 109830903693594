import { RULE } from '@zeiss/ng-vis-vp-auth/constants';
import { AUTH_GUARD } from '@zeiss/ng-vis-vp-auth/injection-tokens';
import { vpRule } from '@zeiss/ng-vis-vp-auth/types';
const TST_PATHS = {
  _: 'tst',
  flow: 'flow',
  handover: 'handover',
  search: 'search',
  config: 'config',
  relabel: 'relabelling',
  mobile: 'mobile',
  mobileHandover: 'mobile/mobile-handover',
  mobilePackaging: 'mobile/mobile-packaging',
  sorting: 'sorting',
  problems: 'problems'
};
const TST_ROUTES = {
  path: TST_PATHS._,
  canActivate: [AUTH_GUARD],
  data: {
    title: 'external.tst.routes.title',
    description: 'external.tst.routes.desc',
    icon: 'zui-icon-volume',
    showInMenu: true,
    rule: vpRule({
      rule: RULE.access_tst,
      required: []
    }),
    routes: [{
      path: ''
    }, {
      path: TST_PATHS.mobile,
      canActivate: [AUTH_GUARD],
      data: {
        icon: 'zui-icon-devices-tablet-devices-tablet-portrait',
        showInMenu: true,
        title: 'external.tst.routes.mobile.title',
        description: 'external.tst.routes.mobile.desc',
        hierarchy: 6,
        rule: vpRule({
          rule: RULE.access_tst,
          required: ['sorting']
        })
      }
    }, {
      path: TST_PATHS.problems,
      canActivate: [AUTH_GUARD],
      data: {
        icon: 'zui-icon-export-add-on-export-add-on-error',
        showInMenu: true,
        title: 'external.tst.routes.problems.title',
        description: 'external.tst.routes.problems.desc',
        hierarchy: 7,
        rule: vpRule({
          rule: RULE.access_tst,
          required: ['problems']
        })
      }
    }, {
      path: TST_PATHS.sorting,
      canActivate: [AUTH_GUARD],
      data: {
        icon: 'zui-icon-move-general',
        showInMenu: true,
        cache: true,
        title: 'external.tst.routes.sorting.title',
        description: 'external.tst.routes.sorting.desc',
        hierarchy: 8,
        rule: vpRule({
          rule: RULE.access_tst,
          required: ['sorting']
        })
      }
    }, {
      path: TST_PATHS.mobileHandover,
      canActivate: [AUTH_GUARD],
      data: {
        icon: 'zui-icon-simulation',
        showInMenu: false,
        title: 'external.tst.routes.mobile.handover.title',
        description: 'external.tst.routes.mobile.handover.desc',
        absoluteUrl: `${TST_PATHS._}/${TST_PATHS.mobile}/${TST_PATHS.mobileHandover}`,
        hierarchy: 9,
        rule: vpRule({
          rule: RULE.access_tst,
          required: ['handover']
        })
      }
    }, {
      path: TST_PATHS.mobilePackaging,
      canActivate: [AUTH_GUARD],
      data: {
        icon: 'zui-icon-volume',
        showInMenu: false,
        cache: false,
        title: 'external.tst.routes.mobile.packaging.title',
        description: 'external.tst.routes.mobile.packaging.desc',
        absoluteUrl: `${TST_PATHS._}/${TST_PATHS.mobile}/${TST_PATHS.mobilePackaging}`,
        hierarchy: 10,
        rule: vpRule({
          rule: RULE.tst_access_flow,
          required: ['load', 'packaging', 'supplements']
        })
      }
    }, {
      path: TST_PATHS.flow,
      canActivate: [AUTH_GUARD],
      data: {
        icon: 'zui-icon-play-play-play-outline',
        showInMenu: true,
        cache: false,
        title: 'external.tst.routes.packingList.title',
        description: 'external.tst.routes.packingList.desc',
        hierarchy: 11,
        rule: vpRule({
          rule: RULE.access_tst,
          required: ['flow']
        })
      }
    }, {
      path: TST_PATHS.search,
      canActivate: [AUTH_GUARD],
      data: {
        icon: 'zui-icon-search-search',
        showInMenu: true,
        cache: true,
        title: 'external.tst.routes.search.title',
        description: 'external.tst.routes.search.desc',
        hierarchy: 12,
        rule: vpRule({
          rule: RULE.access_tst,
          required: ['search']
        })
      }
    }, {
      path: TST_PATHS.handover,
      canActivate: [AUTH_GUARD],
      data: {
        icon: 'zui-icon-simulation',
        showInMenu: true,
        cache: false,
        title: 'external.tst.routes.handover.title',
        description: 'external.tst.routes.handover.desc',
        hierarchy: 13,
        rule: vpRule({
          rule: RULE.access_tst,
          required: ['handover']
        })
      }
    }, {
      path: TST_PATHS.config,
      canActivate: [AUTH_GUARD],
      data: {
        icon: 'zui-icon-settings-settings-settings',
        showInMenu: true,
        cache: true,
        title: 'external.tst.routes.config.title',
        description: 'external.tst.routes.config.desc',
        hierarchy: 14,
        rule: vpRule({
          rule: RULE.access_tst,
          required: ['config']
        })
      }
    }, {
      path: TST_PATHS.relabel,
      canActivate: [AUTH_GUARD],
      data: {
        icon: 'zui-icon-action-not-done-yet',
        showInMenu: true,
        cache: true,
        title: 'external.tst.routes.relabel.title',
        description: 'external.tst.routes.relabel.desc',
        hierarchy: 15,
        rule: vpRule({
          rule: RULE.access_tst,
          required: ['indirx']
        })
      }
    }]
  }
};

/**
 * Generated bundle index. Do not edit.
 */

export { TST_PATHS, TST_ROUTES };
