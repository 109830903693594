import { SystemLanguage } from '@zeiss/ng-vis-common/types';
const deDEExternal = {
  tst: {
    routes: {}
  }
};
const enGBExternal = {
  tst: {
    routes: {
      title: 'TST',
      desc: 'Managing of shipments',
      config: {
        desc: 'Configuring of the workspace',
        title: 'Configuration',
        tutorial: 'In this area you can configure your individual settings'
      },
      handover: {
        desc: 'Handing over of shipments',
        title: 'Shipment handover',
        tutorial: ''
      },
      packingList: {
        desc: 'Creating a shipment',
        title: 'Create shipment',
        tutorial: 'In this area you can create a new shipment , process the packaging including printing of needed supplements and enter the carrier shipment reference'
      },
      search: {
        desc: 'Searching for shipments',
        title: 'Shipment search',
        tutorial: 'In this area you can search for any shipment by using different filter'
      },
      sorting: {
        desc: 'Sorting of shipments',
        title: 'Shipment sorting',
        tutorial: ''
      },
      scanNavigation: {
        desc: 'To navigate between the tabs use the arrows',
        title: 'Navigation'
      },
      scanNavigationCreateShipment: {
        desc: 'You can create a new shipment by pressing the plus button',
        title: 'Create shipment'
      },
      scanNavigationLoadShipment: {
        desc: 'You can load an existing shipment by entering its shipment id and press the enter plus button',
        title: 'Load shipment'
      },
      scanLoadShipment: {
        desc: 'You can load an existing shipment by scanning its shipment id',
        title: 'Scan shipment'
      },
      deleteShipment: {
        desc: 'You can delete an existing shipment by pressing the bin button',
        title: 'Delete shipment'
      },
      packaging: {
        desc: 'In the packaging tab, you can create a packaging structure with handling units to afterwards add the shipment items to the handling units',
        title: 'Add handling unit'
      },
      packagingTree: {
        desc: 'In the packaging structure tree, you can see and manage the packaging structure.</br></br> By selecting a handling unit in the tree the item list on the right side gets filtered and you can add items into the selected handling unit.</br> At the right side of each handling unit you have a few options: You can close or (re)open a handling unit by clicking the lock.</br></br> If any supplements need to be printed a dialog will pop up guiding the printout.</br></br> You can also move or remove one or more handling units to/from an outer handling unit (so called parent) by clicking the … symbol of the wanted outer handling unit. Therefore a dialog will pop up where you can scan the inner handling units. And you also have the option to delete a handling unit by clicking the red bin.',
        title: 'Packaging structure'
      },
      packagingScanHandlingUnit: {
        desc: 'You can create an individual packaging structure. For a new handling unit scan a handling unit type id or choose a type and press the enter plus button',
        title: 'Scan handling unit type'
      },
      shipmentScanShipment: {
        desc: 'By scanning a serial number, the shipment item will be added to the list',
        title: 'Scan shipment items'
      },
      shipmentFilterShipment: {
        desc: 'You can filter for a specific item by typing any characters that are contained in the data you are looking for',
        title: 'Filter shipment items'
      },
      carrier: {
        desc: 'In this view, the carrier is selected. The preferred carrier is already set as default; however, this can also be changed. Optional you can  enter the shipment reference of the carrier',
        title: 'Carrier'
      },
      carrierSave: {
        desc: 'You need to save your changes by pressing the save button',
        title: 'Save carrier'
      },
      searchList: {
        desc: 'In this view you can find all shipments',
        title: 'Shipment result'
      },
      searchFilter: {
        desc: 'By using the filter on the left side, you can search for specific shipments',
        title: 'Shipment filter'
      },
      searchPaging: {
        desc: 'On the right side all shipments for your specific filter settings are listed. You can change the number of items per page or turn to the next page to see all items',
        title: 'Shipment paging'
      },
      shipmentOverview: {
        desc: 'In each window, the shipment overview is shown on the left. You can close this overview by pressing the blue arrow',
        title: 'Shipment overview'
      },
      searchOptions: {
        desc: 'At the very right of the shipments, you have different actions you can process with an individual shipment. You can go to the shipment to see all its details and continue the shipment preparation process, hand the shipment over to the carrier or delete the shipment',
        title: 'Shipment options'
      },
      handoverSortingScan: {
        desc: 'By scanning the sorting code you can find all shipments with that sorting code that are ready for handover',
        title: 'Scan sorting code'
      },
      handoverCarrier: {
        desc: 'By scanning all their carrier shipment references they are marked to be handed over',
        title: 'Scan carrier'
      },
      handoverNavigation: {
        desc: 'After scanning or manually selecting the shipments you want to handover, the system wise handover can be done by pressing this button',
        title: 'Handover'
      },
      handlingUnitsClick: {
        desc: 'By clicking on a handling unit you can filter to the corresponding supplements or shipment items',
        title: 'Handling unit'
      },
      relabel: {
        desc: 'Relabelling',
        title: 'Relabelling',
        tutorial: ''
      },
      mobile: {
        desc: 'Mobile',
        title: 'Mobile',
        handover: {
          desc: 'Handover of shipments',
          title: 'Handover (mobile)'
        },
        packaging: {
          desc: 'Packaging of shipments',
          title: 'Packaging (mobile)'
        }
      },
      problems: {
        desc: 'Shipment problems overview',
        title: 'Shipment problems',
        tutorial: ''
      }
    }
  }
};
const esESExternal = {
  tst: {
    routes: {
      title: 'TST',
      desc: 'Gestión de envíos',
      config: {
        desc: 'Configuración del espacio de trabajo',
        title: 'Configuración'
      },
      handover: {
        desc: 'Entrega de envíos',
        title: 'Entrega de envíos'
      },
      packingList: {
        desc: 'Creación de un envío',
        title: 'Crear envío'
      },
      search: {
        desc: 'Búsqueda de envíos',
        title: 'Búsqueda de envíos'
      }
    }
  }
};
const frFRExternal = {
  tst: {
    routes: {}
  }
};
const zhCNExternal = {
  tst: {
    routes: {
      title: 'TST',
      desc: '货物管理',
      config: {
        desc: '配置工作区',
        title: '配置',
        tutorial: '在此区域，您可以配置个人设置'
      },
      handover: {
        desc: '货物交接',
        title: '货物交接',
        tutorial: ''
      },
      packingList: {
        desc: '创建货物',
        title: '创建货物',
        tutorial: '在此区域，您可以创建新的货物，处理包装，包括打印所需的补充材料，并输入承运人货物参考号'
      },
      search: {
        desc: '搜索货物',
        title: '货物搜索',
        tutorial: '在此区域，您可以使用不同的过滤器搜索任何货物'
      },
      sorting: {
        desc: '货物分类',
        title: '货物分类',
        tutorial: ''
      },
      scanNavigation: {
        desc: '使用箭头在标签页之间导航',
        title: '导航'
      },
      scanNavigationCreateShipment: {
        desc: '您可以通过按下加号按钮创建新货物',
        title: '创建货物'
      },
      scanNavigationLoadShipment: {
        desc: '您可以通过输入货物ID并按回车键加载现有货物',
        title: '加载货物'
      },
      scanLoadShipment: {
        desc: '您可以通过扫描货物ID加载现有货物',
        title: '扫描货物'
      },
      deleteShipment: {
        desc: '您可以通过按下垃圾桶按钮删除现有货物',
        title: '删除货物'
      },
      packaging: {
        desc: '在包装标签中，您可以创建包含处理单元的包装结构，然后将货物项目添加到处理单元中',
        title: '添加处理单元'
      },
      packagingTree: {
        desc: '在包装结构树中，您可以查看和管理包装结构。</br></br> 通过在树中选择一个处理单元，右侧的项目列表将被过滤，您可以将项目添加到所选处理单元中。</br> 在每个处理单元的右侧，您有一些选项：您可以通过点击锁定图标关闭或（重新）打开处理单元。</br></br> 如果需要打印任何补充材料，将弹出一个对话框指导打印。</br></br> 您还可以通过点击所需外部处理单元的…符号，将一个或多个处理单元移动到/从外部处理单元（称为父单元）。因此，将弹出一个对话框，您可以在其中扫描内部处理单元。您还可以通过点击红色垃圾桶删除处理单元。',
        title: '包装结构'
      },
      packagingScanHandlingUnit: {
        desc: '您可以创建个性化的包装结构。对于新的处理单元，请扫描处理单元类型ID或选择一种类型并按回车键',
        title: '扫描处理单元类型'
      },
      shipmentScanShipment: {
        desc: '通过扫描序列号，货物项目将被添加到列表中',
        title: '扫描货物项目'
      },
      shipmentFilterShipment: {
        desc: '您可以通过输入所查找数据中包含的任何字符来过滤特定项目',
        title: '过滤货物项目'
      },
      carrier: {
        desc: '在此视图中，选择承运人。首选承运人已设为默认值，但也可以更改。可选的，您可以输入承运人的货物参考号',
        title: '承运人'
      },
      carrierSave: {
        desc: '您需要按下保存按钮来保存更改',
        title: '保存承运人'
      },
      searchList: {
        desc: '在此视图中，您可以找到所有货物',
        title: '货物结果'
      },
      searchFilter: {
        desc: '通过使用左侧的过滤器，您可以搜索特定货物',
        title: '货物过滤器'
      },
      searchPaging: {
        desc: '右侧列出了符合您特定过滤器设置的所有货物。您可以更改每页的项目数量或转到下一页以查看所有项目',
        title: '货物分页'
      },
      shipmentOverview: {
        desc: '在每个窗口中，左侧显示货物概览。您可以按下蓝色箭头关闭此概览',
        title: '货物概览'
      },
      searchOptions: {
        desc: '在货物的最右侧，您有不同的操作可以对单个货物进行处理。您可以进入货物查看其所有详细信息并继续货物准备过程，将货物交给承运人或删除货物',
        title: '货物选项'
      },
      handoverSortingScan: {
        desc: '通过扫描分类代码，您可以找到所有准备交接的货物',
        title: '扫描分类代码'
      },
      handoverCarrier: {
        desc: '通过扫描它们的所有承运人货物参考号，它们被标记为准备交接',
        title: '扫描承运人'
      },
      handoverNavigation: {
        desc: '扫描或手动选择要交接的货物后，通过按下此按钮可以进行系统交接',
        title: '交接'
      },
      handlingUnitsClick: {
        desc: '点击处理单元，您可以过滤到相应的补充材料或货物项目',
        title: '处理单元'
      },
      relabel: {
        desc: '重新标签',
        title: '重新标签',
        tutorial: ''
      },
      mobile: {
        desc: '移动设备',
        title: '移动设备',
        handover: {
          desc: '货物交接',
          title: '交接（移动设备）'
        },
        packaging: {
          desc: '货物包装',
          title: '包装（移动设备）'
        }
      },
      problems: {
        desc: '货物问题概览',
        title: '货物问题',
        tutorial: ''
      }
    }
  }
};

// Translations which can be used in VP outside library. Please do not export everything, this is exception, not a rule!
const TST_TRANSLATIONS = {
  [SystemLanguage['en-GB']]: enGBExternal,
  [SystemLanguage['de-DE']]: deDEExternal,
  [SystemLanguage['es-ES']]: esESExternal,
  [SystemLanguage['fr-FR']]: frFRExternal,
  [SystemLanguage['zh-CN']]: zhCNExternal
};

/**
 * Generated bundle index. Do not edit.
 */

export { TST_TRANSLATIONS };
